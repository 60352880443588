import { computed, ComputedRef, defineComponent, PropType, Ref, ref } from 'vue'
import { FormErrors, ModalForm, NumberInput } from 'apptimizm-ui'
import { Area, FormFields, FormFieldsMeta } from './types'
import axios from '@/axios'
import { store } from '@/store'
import { howManyAreas } from './index.vue'
import { ButtonLoader } from '@/UI/loader'
import { AxiosError } from 'axios'

export interface IZoneBulkDeleteRequestData {
  project: string
  zones?: string[]
  /* eslint-disable camelcase */
  start_serial_number?: number
  end_serial_number?: number
  /* eslint-enable */
}

export default defineComponent({
  props: {
    resetSelectedAreas: {
      type: Function as PropType<() => void>,
      required: true
    },
    close: {
      type: Function as PropType<() => void>,
      required: true
    },
    selectedAreas: {
      type: Object as PropType<{
        items: Ref<Area[]>
        itemsId: ComputedRef<string[]>
        toggleOneItem: (item: Area) => void
        addRange: (item: Area, tableItems: Area[]) => void
        toggleItem: (item: Area) => void
      }>,
      required: true
    },
    reloadTable: {
      type: Function as PropType<() => void>,
      required: true
    },
    numberMessaggeReload: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const form = ref(new FormFields())
    const formMeta = new FormFieldsMeta()
    const formErrors = ref(new FormErrors({}))

    const isSelectedZones = computed(() => !!props.selectedAreas.itemsId.value.length)
    const isLoading = ref(false)

    const isShowModalDelete = ref(false)

    const isDeleteErrorAreas = ref(false)
    const textErrorReason = ref('')

    const isCheckSelectedAreas = ref(props.selectedAreas.items.value.some((item) => item.isDocuments))

    if (isCheckSelectedAreas.value) isShowModalDelete.value = true

    async function submit () {
      isShowModalDelete.value = false
      isLoading.value = true

      const data: IZoneBulkDeleteRequestData = {
        project: store.currentProject.id
      }

      if (isSelectedZones.value) {
        howManyAreas.value = props.selectedAreas.itemsId.value.length
        data.zones = props.selectedAreas.itemsId.value
      } else {
        const { startSerialNumber, endSerialNumber } = form.value

        howManyAreas.value = Math.abs(endSerialNumber - startSerialNumber)

        data.start_serial_number = startSerialNumber
        data.end_serial_number = endSerialNumber
      }
      try {
        const response = (await axios.post(formMeta.endpointDelete, data)).data

        if (response?.errors) {
          isShowModalDelete.value = true
          isDeleteErrorAreas.value = true
          textErrorReason.value = 'Ошибка! Проверьте поля.'
        } else {
          props.resetSelectedAreas()
          props.close()
        }
      } catch (err) {
        const error = err as AxiosError

        if (error.response?.data?.status_code === 409) {
          isShowModalDelete.value = true
          isDeleteErrorAreas.value = true
          textErrorReason.value = error.response?.data?.errors
        }
      }

      isLoading.value = false
    }

    const closeDeleteForm = () => {
      if (isCheckSelectedAreas.value) props.close()

      isShowModalDelete.value = false
      isDeleteErrorAreas.value = false
    }

    const openConfirmModalForm = () => {
      isShowModalDelete.value = true
    }

    const textConfirmModal = computed(() => {
      return (isDeleteErrorAreas.value || isCheckSelectedAreas.value) ? `${textErrorReason.value.length ? textErrorReason.value : 'В зоне имеются выгруженные документы. Удаление невозможно.'}` : `Вы действительно хотите удалить ${props.selectedAreas.itemsId.value.length ? props.selectedAreas.itemsId.value.length : ''} зон(ы) ${form.value.startSerialNumber ? `с ${form.value.startSerialNumber} по ${form.value.endSerialNumber}` : ''}?`
    })

    const confirmDeleteModalForm = () => (
      <ModalForm
        cancel={closeDeleteForm}
        confirm={() => {}}
        header={(isDeleteErrorAreas.value || isCheckSelectedAreas.value) ? 'Внимание!' : 'Подтверждение'}
        slots={{
          default: () => (<p class="modal-text mt-3">{textConfirmModal.value}</p>),
          buttons: () => (<div class="mt-3">
            <div class="row" style={{ gridTemplateColumns: (isCheckSelectedAreas.value || isDeleteErrorAreas.value) ? '1fr' : 'repeat(2, 1fr)' }}>
              <button onClick={closeDeleteForm} class="default-button">{ (isDeleteErrorAreas.value || isCheckSelectedAreas.value) ? 'OK' : 'Нет, отменить' }</button>
              {(isDeleteErrorAreas.value || isCheckSelectedAreas.value) ? '' : <button onClick={submit} class="danger-button">Да, удалить</button>}
            </div>
          </div>)
        }}
      />
    )

    const modalForm = () => (
      <ModalForm
        cancel={props.close}
        confirm={() => {}}
        header='Удаление зон'
        slots={{
          default: () => (
            <>
              {isSelectedZones.value
                ? <p class='mt-4'>Вы действительно хотите удалить выбранные зоны?</p>
                : <div class='mt-4'>
                  <div class="row cg-10">
                    <label>
                      <NumberInput
                        class={`short input-placeholder-up ${!form.value.startSerialNumber ? 'hide-zero' : ''}`}
                        placeholder="Зоны с..."
                        modelValue={form.value.startSerialNumber}
                        onValueChange={(v: number) => { form.value.startSerialNumber = parseInt(String(v)) } }
                        errors={formErrors.value.forField('startSerialNumber')}
                      />
                    </label>
                    <label>
                      <NumberInput
                        class={`short input-placeholder-up ${!form.value.endSerialNumber ? 'hide-zero' : ''}`}
                        placeholder="по..."
                        modelValue={form.value.endSerialNumber}
                        onValueChange={(v: number) => { form.value.endSerialNumber = parseInt(String(v)) } }
                        errors={formErrors.value.forField('endSerialNumber')}
                      />
                    </label>
                  </div>
                </div>
              }
            </>
          ),
          buttons: () =>
            <button
              onClick={openConfirmModalForm}
              disabled={isLoading.value}
              class="primary-button mt-3">
              <ButtonLoader hidden={!isLoading.value}/>
              Удалить
            </button>
        }}
      />
    )
    return () => (
      <div>
        {modalForm()}
        {isShowModalDelete.value ? confirmDeleteModalForm() : ''}
      </div>
    )
  }
})